body{
    background-color: #ff6666;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.title-body{
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}
.App{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.body-content{
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #fff;
}

.button-content{
  padding: 20px 50px 20px 50px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid #000;
}

.body-content-text{
  padding-top: 20px;
}
.result{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}